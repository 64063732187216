import React, { Suspense } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import Loader from './Components/Loader';
const NavbarMenu = React.lazy(() => import('./Components/Navbar'))
const Footer = React.lazy(()=>import('./Components/Footer'))
const Index = React.lazy(()=>import('./Pages/index'))
const AboutUs = React.lazy(()=>import('./Pages/about'))
const Gallery = React.lazy(()=>import('./Pages/gallery'))
const ContactUs  = React.lazy(()=>import('./Pages/contactUs'))
const Articles = React.lazy(()=>import('./Pages/articles'))
const MyHomeArticle = React.lazy(()=>import('./Pages/myHome'));
const PtSlabsConstruction = React.lazy(()=>import('./Pages/ptSlabsConstruction'));
const ReraApprovalArticle = React.lazy(()=>import('./Pages/reraApprovalArticle'));
const PrivacyPolicy = React.lazy(()=>import('./Pages/Privacy'))
export const Site = () => {
    return(
    <HashRouter basename={process.env.PUBLIC_URL}>
         <Suspense fallback={Loader()}>
            <NavbarMenu />
                <>
                    <Switch>
                        <Route exact path="/" component={Index} />
                        <Route exact path="/about" component={AboutUs} />
                        <Route exact path="/gallery" component={Gallery} />
                        <Route exact path="/contactus" component={ContactUs} />
                        <Route exact path="/articles" component={Articles} />
                        <Route exact path="/articles/myhome-mysanctuary" component={MyHomeArticle} />
                        <Route exact path="/articles/pt-slab-construction" component={PtSlabsConstruction} />
                        <Route exact path="/articles/rera-certified-property" component={ReraApprovalArticle} />
                        <Route exact path="/privacypolicy" component={PrivacyPolicy} />
                    </Switch>
                </>
            <Footer />
        </Suspense>
  </HashRouter>
    );
}
export default Site;