const { Spinner } = require("react-bootstrap")

const Loader = () => {
    return(
        <div className="spin-loader-adjust">
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    )
}
export default Loader;